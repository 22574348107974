<template>
  <app-page hideBottomBar>
    <template v-slot:app-bar>
      <div></div>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        {{ $vuetify.lang.t("$vuetify.step_1_of_2") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/" class="decoration-none">{{
        $vuetify.lang.t("$vuetify.skip")
      }}</router-link>
    </template>

    <div class="auth-container">
      <div>
        <h2>{{$vuetify.lang.t('$vuetify.preferences')}}</h2>
        <p class="subtitle-1">{{ $vuetify.lang.t("$vuetify.family") }}</p>

        <div class="kids">
          <p class="sm">{{ $vuetify.lang.t("$vuetify.kids_in_house") }}</p>
          <counter
            class="kid-counter"
            v-model="noOfKids"
            @addition="updateKids(true)"
            @subtraction="updateKids(false)"
          />
        </div>

        <div class="number-of-kids">
          <div class="kid-item" v-for="(n, index) in kidsAges" :key="index">
            <p class="sm">
              {{ $vuetify.lang.t("$vuetify.enter_age_of_kid") }} {{ index + 1 }}
            </p>
            <counter
              :value="n"
              @input="(val) => kidAgeChange(val, index)"
              :max="40"
              :min="1"
            />
          </div>
        </div>
      </div>

      <div class="mb-10">
        <app-button class="mt-3" @click="nextStep">{{
          $vuetify.lang.t("$vuetify.next")
          
        }}</app-button>
      </div>
    </div>
  </app-page>
</template>

<script>
import counter from "@/components/counter/counter";

import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapGetters, mapState } = createNamespacedHelpers("user");
export default {
  name: "preference-1",
  components: { counter },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["GET_USER_PREFERENCE"]),
    noOfKids: {
      get() {
        return this.GET_USER_PREFERENCE("noOfKids");
      },
      set(value) {
        this.SET_USER_PREFERENCE({ key: "noOfKids", value });
      },
    },
    kidsAges() {
      return this.GET_USER_PREFERENCE("kidsAges");
    },
  },
  methods: {
    ...mapMutations(["SET_USER_PREFERENCE", "SET_ALL_USER_PREFERENCE"]),
    updateKids(isAddition) {
      if (isAddition) {
        const ages = this.GET_USER_PREFERENCE("kidsAges");
        const kidAges = [...ages];
        kidAges.push(1);
        this.SET_USER_PREFERENCE({ key: "kidsAges", value: kidAges });
      } else {
        const kidAges = [...this.GET_USER_PREFERENCE("kidsAges")];
        kidAges.splice(kidAges.length - 1, 1);
        this.SET_USER_PREFERENCE({ key: "kidsAges", value: kidAges });
      }
    },
    kidAgeChange(val, index) {
      const ages = [...this.GET_USER_PREFERENCE("kidsAges")];
      ages[index] = val;
      this.SET_USER_PREFERENCE({ key: "kidsAges", value: [...ages] });
    },
    nextStep() {
      this.$router.push("/preference-2");
    },
  },
  created() {
    const { meta_data } = this.user;

    if (meta_data && meta_data !== "null") {
      this.SET_ALL_USER_PREFERENCE(meta_data);
    }
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  min-height: calc(100vh - 56px - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;

  @media only screen and (min-width: 400px) {
    padding-bottom: 3rem;
  }

  p.sm {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }

  .kids {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .kid-counter {
      padding: 0.3rem 0;
      border: 1px solid #333;
      border-radius: 12px;
    }
  }

  .number-of-kids {
    margin-top: 2rem;

    .kid-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #333;
      border-radius: 12px;
      margin-bottom: 0.5rem;
      padding: 0.3rem 1rem;
    }
  }
}
</style>
